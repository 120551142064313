import React from "react"
import NavBar from "../components/nav-bar"
import BlogList from "../components/blog-list"
import Footer from "../components/footer"
import "../styles/scss/custom.scss"
import "font-awesome/css/font-awesome.css"

const Blog = () => {
    return (
        <div className="container-fluid">
        <NavBar />
        <BlogList />
        <Footer />
        </div>
    )
}

export default Blog