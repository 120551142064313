import * as React from 'react';
const Footer = () => {
    return (
      <footer>
      <div className="text-right">
    &copy; 2021 Thrifty Daze
    </div>
    </footer>
);
    };

export default Footer;