import * as React from "react"
import { ThemeContext } from './theme-context'

const ThemeSwitch = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);
    if (!theme) {
        return null;
    }
    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
      }
    return (
        <button
          className={`btn ${theme === 'dark' ? 'btn-outline-light' : 'btn-outline-dark'}`}
          onClick={toggleTheme}
        ><i className="fa fa-adjust" aria-label="Light/Dark Toggle"></i></button>
    )
}

export default ThemeSwitch
