import React from "react"
import { Link, graphql, useStaticQuery} from "gatsby"
import { ThemeContext } from './theme-context'

const BlogList = () => {
    const posts = useStaticQuery(graphql`
    query BlogListQuery {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            excerpt(pruneLength: 250)
            id
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              path
            }
          }
        }
      }
    }
  `);

  const theme = React.useContext(ThemeContext).theme;

    return (
      <div className="container mt-5">
        {posts.allMarkdownRemark.edges
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
                <div className="row mt-1" key={post.id}>
                <div className="col">
                <div className={`card bg-${theme} border-${theme === 'light'? 'dark' : 'light'}`}>
                <div className="card-body">
                <p className="card-title">
                  <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                </p>
                <p className="card-text">{post.excerpt} {post.frontmatter.date}</p>
                </div>
                </div>
              </div>
              </div>
            )
          })}
      </div>
    )
  }

export default BlogList;