import * as React from 'react';
import { Link } from "gatsby";
import ThemeSwitch from "../components/theme-switch";
import navLogo from "../../static/logo_tdaze_dark.png";
const NavBar = () => {
    return (
  <div className="row fixed-top d-flex flex-row-reverse mr-2">
    <ThemeSwitch />
    <div>
    <Link to="/">
          <img className="rounded" src={navLogo} width="150" height="38" />
      </Link>
    </div>
  </div>
);
    };

export default NavBar;